import React from 'react';
import {
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import Navigation from '../components/slices/Navigation/Navigation';
import Footer from '../components/slices/Footer/Footer';

const Page404 = ({ data }) => {
  if (!data) return null;

  const navigationMenu = data.prismicNavigation.data;
  const footerMenu = data.prismicFooter.data;
  return (
    <div>
      <Navigation navigationMenu={navigationMenu} />
      <div className="not-found">
        <h1>404</h1>
        <h3>The page you are looking for was not found</h3>
        <p>
          <a href="/">
            <button type="button">Return to homepage</button>
          </a>
        </p>
      </div>
      <Footer footerMenu={footerMenu} />
    </div>
  );
};
export default withPrismicUnpublishedPreview(Page404);

export const query = graphql`
query page404Query ($lang: String) {
  prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
    ...FooterFragment
  }
}`;
